import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const FooterWrapper = styled.footer`
  background-color: #f2eee3;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 0.8rem;
  padding: 40px 0;
`

const FooterTitle = styled.div`
  color: #3e3e3e;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: bold;
`

const FirstName = styled.span`
  color: #375f70;
`

const Copyright = styled.span`
  color: #999999;
`

const FooterLinks = styled.div`
  margin-top: 20px;

  @media (min-width: 620px) {
    float: right;
    margin-top: 0;
  }
`

const FooterLink = styled(Link)`
  &:last-child {
    padding-left: 20px;
  }
`

export default () => (
  <FooterWrapper>
    <div className="container container--padded cf">
      <FooterTitle>
        <FirstName>Geoff</FirstName> Beattie
      </FooterTitle>
      <Copyright>© Geoff Beattie. All Rights Reserved.</Copyright>
      <FooterLinks>
        <FooterLink to="/privacy-policy/">Privacy Policy</FooterLink>
        <FooterLink to="/terms-conditions/">Terms &amp; Conditions</FooterLink>
      </FooterLinks>
    </div>
  </FooterWrapper>
)
