import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./components/header"
import Navigation from "./components/navigation"
import "./reset.css"
import "./main.css"
import SEO from "./components/seo"
import Footer from "./components/footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          subTitle
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Lato|Source+Sans+Pro:600&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Header
        title={data.site.siteMetadata.title}
        subTitle={data.site.siteMetadata.subTitle}
      />

      <Navigation links={data.site.siteMetadata.menuLinks} />
      <main role="main">{children}</main>
      <Footer />
      <SEO />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
