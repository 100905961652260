import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import "./navigation.css"

const Navigation = styled.nav`
  @media (min-width: 960px) {
    width: 960px;
    margin: auto;
  }
`

export default ({ links }) => (
  <Navigation role="navigation" id="menuToggle">
    <input type="checkbox" />
    <span></span>
    <span></span>
    <span></span>
    <ul id="menu">
      {links.map(item => (
        <li key={item.name}>
          <Link to={item.link}>{item.name}</Link>
        </li>
      ))}
    </ul>
  </Navigation>
)
