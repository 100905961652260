import React from "react"
import styled from "styled-components"

const HeaderWrapper = styled.div`
  border-top: 5px solid #8baabd;

  @media (min-width: 768px) {
    border-bottom: 1px solid #cdcdcd;
  }
`

const Header = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
`

const Heading = styled.h1`
  color: #3e3e3e;
  text-transform: uppercase;
  font-size: 2.6rem;
  margin: 0 60px 5px 0;

  @media (min-width: 768px) {
    font-size: 2.8rem;
    float: left;
    margin-right: 0;
  }
`

const FirstName = styled.span`
  color: #375f70;
`

const SubHeading = styled.p`
  margin: 0;
  font-size: 1.1rem;
  font-weight:bold;
  color: #375f70;
  margin-left 2px;

  @media (min-width: 768px) {
    float: right;
    padding: 5px 3px 0 0;
    font-size: 1.2rem;
  }
`

export default ({ subTitle }) => (
  <HeaderWrapper>
    <Header className="container container--padded cf">
      <Heading className="tf-title">
        <FirstName>Geoff</FirstName> Beattie
      </Heading>
      <SubHeading className="tf-body">{subTitle}</SubHeading>
    </Header>
  </HeaderWrapper>
)
