import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const SidePanel = styled.div`
  width: 32%;
  padding-right: 30px;
  float: left;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`

const SidePanelImage = styled(Img)`
  width: 100%;
  margin-bottom: 20px;
`

const QuoteWrapper = styled.div`
  background: #abc2d0;
  text-align: center;
  padding: 20px 30px;
  border-bottom: 5px solid #405e71;
  overflow: hidden;
  font-size: 1.1rem;
`

const Quote = styled.blockquote`
  margin: 0 0 20px;
  color: #fff;
  font-style: italic;
  font-weight: bold;
`

const QuoteBar = styled.hr`
  display: block;
  height: 1px;
  width: 80%;
  background: #fff;
  margin: 20px auto;
`

export default ({ image, quote, author }) => {
  let quoteComponent
  let quoteAuthorComponent

  if (author) {
    quoteAuthorComponent = <p>{author}</p>
  }

  if (quote) {
    quoteComponent = (
      <QuoteWrapper>
        <QuoteBar />
        <Quote>&ldquo;{quote}&rdquo;</Quote>
        {quoteAuthorComponent}
        <QuoteBar />
      </QuoteWrapper>
    )
  }

  return (
    <SidePanel>
      <SidePanelImage
        sizes={{ ...image.childImageSharp.fluid, aspectRatio: 1 / 1 }}
      />
      {quoteComponent}
    </SidePanel>
  )
}
